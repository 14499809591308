import styled from 'styled-components';

export const Templates = styled.div`
  margin-top: 24px;
`;

export const Previews = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 24px;

  > * {
    flex: 1;
    align-self: stretch;

    display: flex;
    flex-direction: column;

    .message .ql-editor {
      max-height: none !important;
    }

    .message {
      height: 100%;
    }

    &:first-child {
      *:has(.message) {
        height: 100%;
      }
    }
  }
`;

export const PreviewLabel = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 4px;
`;

export const FormFieldLabel = styled.label`
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Asterisk = styled.span`
  color: #d54855;
`;

export const PreviewContainer = styled.div`
  border-radius: 16px;
  border: 1px solid #dfdfe8;
  background-color: #fff;
  padding: 12px;
  max-width: 372px;
  height: 100%;

  font-size: 14px;
  font-weight: 400;

  line-height: 20px;

  p {
    margin: 4px 0;
  }

  .variable {
    background-color: #cceae7;
    border-radius: 4px;
    color: #009688;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.25;
    padding: 1px 3px;
    white-space: nowrap;
  }
`;

export const Subject = styled.div`
  border-bottom: 1px solid #dfdfe8;
  padding: 0 12px 8px;
  margin: 0 -12px;
  font-weight: 500;
`;

export const RemindersSettings = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ReminderSettingRow = styled.div`
  font-size: 14px;

  display: flex;
  gap: 8px;
  align-items: center;

  && {
    > * {
      margin-bottom: 0;

      &:nth-child(2) {
        width: 56px;
      }

      &:nth-child(3) {
        width: 136px;
      }
    }
  }
`;

export const TimeSelectContainer = styled.div`
  width: 100px;
  > * {
    margin-bottom: 0;
  }
`;

export const CollapsibleReminder = styled.div`
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #dfdfe8;

  margin-top: 16px;
`;
