import React, { useCallback, useState } from 'react';
import { Tabs } from '@kit/ui/Tabs';
import { RichTextField } from '@kit/components/Form';
import { Control, UseFormWatch } from 'react-hook-form';
import { useAppSelector } from '@hooks/index';
import { selectCompanyById, selectWorkspaceId } from '@state/selectors';
import { EventType } from '@generated/types/graphql';
import {
  clearQuillValueForSms,
  convertQuillFormatToText,
  replacePatternsInString,
  TOKEN_TEXT_PATTERN
} from '@utils/quill';
import { TOKENS_BY_EVENT_TYPE } from './tokens';
import { EmailTemplate, getButtonHtml } from './EmailTemplate';
import { TEMPLATE_TABS } from './constants';
import { PreviewContainer, PreviewLabel, Previews, Templates } from './styled';
import { config } from '../../../../../../config';

interface Props<TFormValues> {
  control: Control<TFormValues>;
  emailSubjectFieldName: string;
  emailTextFieldName: string;
  smsTextFieldName: string;
  eventType: EventType;
  watch: UseFormWatch<TFormValues>;
}

export const TemplateContentEditor = <TFormValues,>({
  watch,
  eventType,
  control,
  emailSubjectFieldName,
  emailTextFieldName,
  smsTextFieldName
}: Props<TFormValues>) => {
  const [selectedTab, setSelectedTab] = useState(TEMPLATE_TABS[0]);

  const companyId = useAppSelector(selectWorkspaceId);
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const portalUrl = `${config.portalUrl}/${company?.subdomainName ?? ''}`;

  const getTokens = useCallback(() => {
    return TOKENS_BY_EVENT_TYPE[eventType] ?? [];
  }, [eventType]);

  const previewTextWithValues = (template: string, isPlainText = false) => {
    const tokens = getTokens();

    return replacePatternsInString(template, TOKEN_TEXT_PATTERN, (...rest) => {
      const tokenId = rest[2];

      let value;

      if (tokenId === 'accessUrl') {
        value = isPlainText ? portalUrl : getButtonHtml(portalUrl, 'Click here to view');
      } else if (tokenId === 'company.name') {
        value = company?.name;
      } else {
        const token = tokens.find((token) => token.id === tokenId);

        if (!token) {
          value = '';
        } else {
          value = token?.exampleValue ?? token?.value;
        }
      }

      return value;
    });
  };

  const [emailNotificationText, smsNotificationText] = watch([emailTextFieldName, smsTextFieldName]);

  return (
    <Templates>
      <Tabs variant="outline" tabs={TEMPLATE_TABS} selected={selectedTab.id} onChange={setSelectedTab} />

      {selectedTab.id === 'email' && (
        <Previews>
          <div>
            <RichTextField
              getTokens={getTokens}
              isOneLine
              isToolbarHiddenCompletely
              placeholder=""
              label="Email subject"
              name={emailSubjectFieldName}
              control={control}
              clearOnUnmount={false}
            />

            <RichTextField
              getTokens={getTokens}
              isToolbarHiddenCompletely
              placeholder=""
              label="Email message"
              name={emailTextFieldName}
              control={control}
              clearOnUnmount={false}
              className="message"
            />
          </div>
          <div>
            <PreviewLabel>Email preview</PreviewLabel>
            <PreviewContainer>
              <EmailTemplate logoUrl={company.logoUrl} website={company.website} address={company.address}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: previewTextWithValues(convertQuillFormatToText(emailNotificationText))
                  }}
                />
              </EmailTemplate>
            </PreviewContainer>
          </div>
        </Previews>
      )}

      {selectedTab.id === 'sms' && (
        <Previews>
          <div>
            <RichTextField
              getTokens={getTokens}
              isToolbarHiddenCompletely
              placeholder=""
              label="SMS message"
              name={smsTextFieldName}
              control={control}
              clearOnUnmount={false}
            />
          </div>
          <div>
            <PreviewLabel>SMS preview</PreviewLabel>
            <PreviewContainer>
              <div
                dangerouslySetInnerHTML={{
                  __html: previewTextWithValues(
                    clearQuillValueForSms(convertQuillFormatToText(smsNotificationText)),
                    true
                  )
                }}
              />
            </PreviewContainer>
          </div>
        </Previews>
      )}
    </Templates>
  );
};
