import React from 'react';
import { Switch } from '@kit/ui/Switch';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Bell } from 'react-feather';
import {
  Container,
  SettingsPanelContent,
  SettingsPanelHeader,
  SettingsPanelSecondaryText,
  SettingsPanelTitle,
  SettingsPanelToggle
} from './styled';

interface Props {
  title: string;
  description: string;
  isEnabled?: boolean;
  isEnabable?: boolean;
  onToggleEnabled: (isActive: boolean) => void;
  onEdit?: () => void;
  children?: React.ReactNode;
}

export const SettingsBlock = ({
  title,
  description,
  isEnabled = false,
  isEnabable = true,
  onEdit,
  onToggleEnabled,
  children
}: Props) => {
  return (
    <Container>
      <SettingsPanelToggle>
        {isEnabable ? <Switch isActive={isEnabled} onChange={onToggleEnabled} /> : <Bell size="24px" color="#9C9CAA" />}
      </SettingsPanelToggle>
      <SettingsPanelContent>
        <SettingsPanelHeader>
          <div>
            <SettingsPanelTitle>{title}</SettingsPanelTitle>
            <SettingsPanelSecondaryText>{description}</SettingsPanelSecondaryText>
          </div>
          {onEdit && (
            <Button onClick={onEdit} disabled={!isEnabled} variant={ButtonVariant.Flat}>
              Edit
            </Button>
          )}
        </SettingsPanelHeader>
        {isEnabled && children}
      </SettingsPanelContent>
    </Container>
  );
};
