import React, { useCallback } from 'react';
import { selectCompanySettings } from '@state/selectors';
import { useDebouncedState } from '@hooks/useDebouncedState';
import { useAppSelector } from '@hooks/store';
import { useUpdateEffect } from '@react-hookz/web';
import { useModal } from '@common/PromiseModal';
import { useUpdateCompany } from '@hooks/useUpdateCompany';
import { SettingsBlock } from '../SettingsBlock';
import { ErrorText, SecondaryText, TextBlock } from './styled';
import { ReviewChannel, ReviewChannelType, ReviewsSettings } from './types';
import { EditForm } from './EditForm';
import { SettingsPanel } from '../styled';

const DEFAULT_CHANNELS: ReviewChannel[] = [
  {
    type: ReviewChannelType.Google,
    url: ''
  },
  {
    type: ReviewChannelType.Yelp,
    url: ''
  },
  {
    type: ReviewChannelType.BBB,
    url: ''
  }
];

const defaultSettings: ReviewsSettings = {
  enabled: true,
  channels: DEFAULT_CHANNELS
};

export const Reviews = () => {
  const settings = useAppSelector(selectCompanySettings)?.portalReviews ?? defaultSettings;

  const [isEnabled, isEnabledDebounced, setIsEnabled] = useDebouncedState(settings.enabled, 300);

  const isFilled = settings.channels.some((channel) => channel.url);
  const { openModal } = useModal();
  const { mutateAsync: updateCompany } = useUpdateCompany();

  useUpdateEffect(() => {
    updateCompany({
      settings: {
        portalReviews: { ...settings, enabled: isEnabledDebounced }
      }
    });
  }, [isEnabledDebounced]);

  const handleEdit = useCallback(() => {
    openModal<void>(({ onClose }) => <EditForm settings={settings} onClose={onClose} />, {
      title: 'Edit review settings'
    });
  }, [openModal, settings]);

  return (
    <SettingsPanel>
      <SettingsBlock
        title="Client can leave a review"
        description="Display “Leave us a review” widget in Client Portal."
        isEnabled={isEnabled}
        onToggleEnabled={setIsEnabled}
        onEdit={handleEdit}
      >
        {isEnabled && (
          <TextBlock>
            <SecondaryText>Review channels:</SecondaryText>

            {!isFilled && <ErrorText>Links are not added. This widget is hidden.</ErrorText>}

            {isFilled && (
              <div>
                {settings.channels
                  .filter((channel) => channel.url)
                  .map((channel) => channel.type)
                  .join(', ')}
              </div>
            )}
          </TextBlock>
        )}
      </SettingsBlock>
    </SettingsPanel>
  );
};
