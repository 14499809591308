import { useQuery } from 'react-query';
import {
  PortalNotificationEntityDecisionTree,
  PortalNotificationEventDecisionTree,
  CompanyNotificationSetting
} from '@generated/types/graphql';
import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { apiErrorHandler } from '@utils';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector } from '@hooks/store';

export type PortalNotificationEventSettings = Pick<
  CompanyNotificationSetting,
  | 'eventType'
  | 'entityType'
  | 'smsEnabled'
  | 'emailEnabled'
  | 'enabled'
  | 'smsNotificationText'
  | 'emailNotificationSubject'
  | 'emailNotificationText'
  | 'offset1Time'
  | 'offset1SpecifiedTime'
  | 'offset1Unit'
  | 'offset1Email'
  | 'offset1Sms'
  | 'offset2SmsNotificationText'
  | 'offset2EmailNotificationSubject'
  | 'offset2EmailNotificationText'
  | 'offset2Time'
  | 'offset2SpecifiedTime'
  | 'offset2Unit'
  | 'offset2Email'
  | 'offset2Sms'
>;

export type PortalNotificationEvent = {
  eventSchema: PortalNotificationEventDecisionTree;
  settings: PortalNotificationEventSettings;
};

export type PortalNoficationSettingsTreeItem = Omit<PortalNotificationEntityDecisionTree, 'events'> & {
  events: PortalNotificationEvent[];
};

export const usePortalNotifications = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery([ReactQueryKey.WorkspacePortalNotificationsConfig, companyId], async () => {
    try {
      const { portalNotificationDecisionTree, companyNotificationSettings } = await postGraphql<{
        portalNotificationDecisionTree: PortalNotificationEntityDecisionTree[];
        companyNotificationSettings: CompanyNotificationSetting[];
      }>(
        gql`
          query PORTAL_NOTIFICATION_SETTINGS_QUERY($companyId: Int!) {
            portalNotificationDecisionTree {
              entityType
              title
              description
              events {
                eventType
                always
                title
                description

                smsTemplate
                emailTemplate {
                  subject
                  body
                }

                scheduled

                offset1Time
                offset1SpecifiedTime
                offset1Unit
                offset1Email
                offset1Sms

                offset2Time
                offset2SpecifiedTime
                offset2Unit
                offset2Email
                offset2Sms
              }
            }

            companyNotificationSettings(filter: { companyId: { equalTo: $companyId } }) {
              entityType
              eventType
              smsEnabled
              emailEnabled
              enabled

              smsNotificationText
              emailNotificationSubject
              emailNotificationText

              offset1Time
              offset1SpecifiedTime
              offset1Unit
              offset1Email
              offset1Sms

              offset2SmsNotificationText
              offset2EmailNotificationSubject
              offset2EmailNotificationText

              offset2Time
              offset2SpecifiedTime
              offset2Unit
              offset2Email
              offset2Sms
            }
          }
        `,
        { companyId }
      );

      const result: PortalNoficationSettingsTreeItem[] = portalNotificationDecisionTree
        .map((entity) => {
          const events = entity.events
            .map((eventSchema) => {
              const setting = companyNotificationSettings.find(
                (setting) => setting.entityType === entity.entityType && setting.eventType === eventSchema.eventType
              );

              if (!setting) {
                return null;
              }

              const settings: PortalNotificationEventSettings = {
                eventType: setting.eventType,
                entityType: setting.entityType,
                smsEnabled: setting.smsEnabled,
                emailEnabled: setting.emailEnabled,
                enabled: setting.enabled,
                smsNotificationText: setting.smsNotificationText ?? eventSchema.smsTemplate,
                emailNotificationSubject: setting.emailNotificationSubject ?? eventSchema.emailTemplate?.subject,
                emailNotificationText: setting.emailNotificationText ?? eventSchema.emailTemplate?.body,
                offset1Time: setting.offset1Time ?? eventSchema.offset1Time,
                offset1SpecifiedTime: setting.offset1SpecifiedTime ?? eventSchema.offset1SpecifiedTime,
                offset1Unit: setting.offset1Unit ?? eventSchema.offset1Unit,
                offset1Email: setting.offset1Email ?? eventSchema.offset1Email,
                offset1Sms: setting.offset1Sms ?? eventSchema.offset1Sms,
                offset2SmsNotificationText: setting.offset2SmsNotificationText ?? eventSchema.smsTemplate,
                offset2EmailNotificationSubject:
                  setting.offset2EmailNotificationSubject ?? eventSchema.emailTemplate?.subject,
                offset2EmailNotificationText: setting.offset2EmailNotificationText ?? eventSchema.emailTemplate?.body,
                offset2Time: setting.offset2Time ?? eventSchema.offset2Time,
                offset2SpecifiedTime: setting.offset2SpecifiedTime ?? eventSchema.offset2SpecifiedTime,
                offset2Unit: setting.offset2Unit ?? eventSchema.offset2Unit,
                offset2Email: setting.offset2Email ?? eventSchema.offset2Email,
                offset2Sms: setting.offset2Sms ?? eventSchema.offset2Sms
              };

              return {
                eventSchema,
                settings
              };
            })
            .filter(Boolean);

          return {
            ...entity,
            events
          };
        })
        .filter((entity) => entity.events.length > 0);

      return result;
    } catch (e) {
      throw apiErrorHandler('Error fetching Portal Notification settings', e);
    }
  });
};
