import { ReactQueryKey } from '@enums';
import { PrivilegedTask, Reminder } from '@generated/types/graphql';
import { useMemo } from 'react';
import { AccountStatus, RecordType } from '@types';
import { createGlobalState } from 'react-use';
import { useReminders } from '@hooks/useReminders';
import { ClientFilterState, useClientFilterState } from '../useClientFilterState';
import { usePaginatedTasks } from '../useTasks';
import { FlattenedVisits, flattenVisits } from '../flattenVisits';

type TaskOrReminder = PrivilegedTask | Reminder;

// This is not Task nor Visit, a Frankenstein of the two
export type DispatcherTask = PrivilegedTask & FlattenedVisits & { isFilteredOut: boolean } & { assigneeResId: number };

// TODO types
export const useFilterState = createGlobalState<any>({});

const filterTasks = (tasks: TaskOrReminder[], filters: ClientFilterState) => {
  return tasks.map((task) => {
    const filteredOutTask = { ...task, isFilteredOut: true };
    const isReminder = 'dueDate' in task;

    if (filters.query.length > 0) {
      if (
        !task.project.title.toLowerCase().includes(filters.query) &&
        !task.title.toLowerCase().includes(filters.query) &&
        !task.project.address?.some((line) => line.toLowerCase().includes(filters.query)) &&
        !task.project.city?.toLowerCase().includes(filters.query) &&
        !task.project.state?.toLowerCase().includes(filters.query) &&
        !task.project.street?.toLowerCase().includes(filters.query) &&
        !task.project.zipcode?.toLowerCase().includes(filters.query)
      ) {
        return filteredOutTask;
      }
    }

    if ((filters.labels.length > 0 || filters.priorities.length > 0 || filters.statuses.length > 0) && isReminder) {
      return filteredOutTask;
    }

    if (filters.clientStatuses.length > 0 || filters.projectStatuses.length > 0 || filters.requestStatuses.length > 0) {
      if (
        task.project.type === RecordType.ACCOUNT &&
        !filters.clientStatuses.includes(task.project.accountStatus as AccountStatus | null)
      ) {
        return filteredOutTask;
      }

      if (task.project.type === RecordType.PROJECT && !filters.projectStatuses.includes(task.project.status)) {
        return filteredOutTask;
      }

      if (task.project.type === RecordType.DEAL && !filters.requestStatuses.includes(task.project.requestStatus)) {
        return filteredOutTask;
      }
    }

    if (filters.reminderTypes.length > 0 && filters.templates.length === 0 && !isReminder) {
      return filteredOutTask;
    }

    if (filters.reminderTypes.length === 0 && filters.templates.length > 0 && isReminder) {
      return filteredOutTask;
    }

    if (isReminder) {
      if (filters.reminderTypes.length === 0) {
        return task;
      }

      if (task.isAppointment && filters.reminderTypes.includes('appointment')) {
        return task;
      }

      return !task.isAppointment && filters.reminderTypes.includes(task.type) ? task : filteredOutTask;
    }

    if (filters.templates.length > 0 && !filters.templates.includes(task.templateTaskId)) {
      return filteredOutTask;
    }

    if (
      filters.labels.length > 0 &&
      !filters.labels.some((id) => task.privilegedTaskLabels.find((taskLabel) => taskLabel.label?.id === id))
    ) {
      return filteredOutTask;
    }

    if (filters.priorities.length > 0 && !filters.priorities.includes(task.priority)) {
      return filteredOutTask;
    }

    if (filters.statuses.length > 0 && !filters.statuses.includes(task.taskStatus.id)) {
      return filteredOutTask;
    }

    return task;
  });
};

const flattenCollaborators = (tasks: (PrivilegedTask | Reminder)[]) => {
  return tasks
    .map((task) => {
      if ('dueDate' in task) {
        return {
          ...task,
          assigneeResId: task.assignee?.id
        };
      }

      if (task.assignee || task.assigneesByTaskId.length) {
        const result = [];
        if (task.assignee) {
          result.push({ ...task, assigneeResId: task.assignee.id });
        }

        task.assigneesByTaskId.forEach((assignee) => {
          result.push({
            ...task,
            isCollaborator: true,
            assigneeResId: assignee.user.id
          });
        });

        return result;
      }

      return task;
    })
    .flat();
};

export const useDispatherTasks = () => {
  const [filters] = useFilterState();
  const [clientFilters] = useClientFilterState();

  const { tasks, total } = usePaginatedTasks({
    queryKey: ReactQueryKey.TasksDispatcher,
    perPage: 2000,
    isEnabled: Boolean(filters.dateRangeFilter),
    filters: {
      assigneeExists: true,
      or: [
        {
          startDate: {
            greaterThanOrEqualTo: filters.dateRangeFilter?.startDate,
            lessThanOrEqualTo: filters.dateRangeFilter?.endDate
          }
        },
        {
          endDate: {
            greaterThanOrEqualTo: filters.dateRangeFilter?.startDate,
            lessThanOrEqualTo: filters.dateRangeFilter?.endDate
          }
        },
        {
          startDate: {
            lessThanOrEqualTo: filters.dateRangeFilter?.startDate
          },
          endDate: {
            greaterThanOrEqualTo: filters.dateRangeFilter?.endDate
          }
        },
        {
          taskVisitsByTaskId: {
            some: {
              startDate: {
                greaterThanOrEqualTo: filters.dateRangeFilter?.startDate,
                lessThanOrEqualTo: filters.dateRangeFilter?.endDate
              }
            }
          }
        }
      ]
    }
  });

  const { data: reminders = [] } = useReminders({ project: { isActive: { equalTo: true } } });

  const filteredWorkOrders = useMemo(
    () => filterTasks(tasks, clientFilters) as PrivilegedTask[],
    [tasks, clientFilters]
  );

  const filteredTasks = useMemo(() => {
    const filteredTasks = filterTasks([...tasks, ...reminders], clientFilters);

    return {
      tasks: flattenCollaborators(flattenVisits(filteredTasks)),
      visibleCount: filteredTasks.length
    };
  }, [tasks, clientFilters, reminders]);

  return {
    allWorkOrders: tasks,
    filteredWorkOrders,
    tasks: filteredTasks.tasks as DispatcherTask[],
    visibleCount: filteredTasks.visibleCount,
    total: total + reminders.length
  };
};
