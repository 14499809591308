import styled from 'styled-components';

export const Description = styled.div`
  font-size: 14px;

  b {
    font-weight: 500;
  }
`;

export const Caption = styled.div`
  color: #828d9a;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
`;
