import { useModal } from '@common/PromiseModal';
import { useActiveRecordsCountByWorkflow } from '@hooks/useRecords';
import React, { useCallback } from 'react';
import { ConfirmAddResult, ConfirmAdd } from './ConfirmAdd';

export const useConfirmAddWO = (blueprintId: number) => {
  const { openModal } = useModal();

  const { refetch } = useActiveRecordsCountByWorkflow({ workflowId: blueprintId, isEnabled: false });

  return useCallback(
    async ({
      woTemplate,
      stage,
      workflowStageId,
      sliceId
    }: {
      woTemplate: { id: number; title: string; isField: boolean };
      stage: { id: number; name: string };
      workflowStageId: number;
      sliceId?: number;
    }) => {
      const { data: existingProjects } = await refetch();

      if (!existingProjects) {
        return { action: ConfirmAddResult.Create, addToFeed: true };
      }

      const result = await openModal<{ action: ConfirmAddResult; addToFeed: boolean } | void>(
        ({ onClose }) => (
          <ConfirmAdd
            workflowId={blueprintId}
            workflowStageId={workflowStageId}
            sliceId={sliceId}
            workOrderTemplate={woTemplate}
            stage={stage}
            onClose={onClose}
          />
        ),
        { title: 'Confirm Workflow changes' }
      );

      return result;
    },
    [openModal, refetch, blueprintId]
  );
};
