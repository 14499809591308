import { EventType } from '@generated/types/graphql';

type Token = {
  id: string;
  value: string;
  exampleValue?: string;
};

const COMMON_TOKENS: Token[] = [
  {
    id: 'company.name',
    value: 'Company Name'
  },
  {
    id: 'contact.name',
    value: 'Homeowner Name',
    exampleValue: 'John'
  },
  {
    id: 'accessUrl',
    value: 'Link to Portal'
  }
];

const TASK_VISIT_COMMON_TOKENS: Token[] = [
  {
    id: 'task.title',
    value: 'Work Order name',
    exampleValue: 'Installation'
  },
  {
    id: 'visit.startDateDate',
    value: 'Visit start date',
    exampleValue: 'Feb, 15, 2024'
  },
  {
    id: 'visit.startDateTime',
    value: 'Visit start time',
    exampleValue: '9:00 am'
  },
  {
    id: 'task.address',
    value: 'Address',
    exampleValue: 'Porter St, San Francisco'
  }
];

const ACTION_ITEM_COMMON_TOKENS: Token[] = [
  {
    id: 'action.name',
    value: 'Action Item name',
    exampleValue: 'Upload Utility Bills'
  },
  {
    id: 'callToAction',
    value: 'Call to action',
    exampleValue: 'upload'
  }
];

export const TOKENS_BY_EVENT_TYPE: { [key in EventType]?: Token[] } = {
  [EventType.PortalMessageReceived]: [
    ...COMMON_TOKENS,
    {
      id: 'author.name',
      value: 'Author Name',
      exampleValue: 'Kate Austin'
    },
    {
      id: 'comment.comment',
      value: 'Comment',
      exampleValue: 'We are happy to help you with your request'
    }
  ],
  [EventType.PortalAppointmentDueDateApproaching]: [
    ...COMMON_TOKENS,
    {
      id: 'reminder.dueDateDate',
      value: 'Appointment date',
      exampleValue: 'Feb, 15, 2024'
    },
    {
      id: 'reminder.dueDateTime',
      value: 'Appointment time',
      exampleValue: '9:00 am'
    },
    {
      id: 'task.address',
      value: 'Appointment address',
      exampleValue: 'Porter St, San Francisco'
    }
  ],
  [EventType.PortalVisitScheduled]: [...COMMON_TOKENS, ...TASK_VISIT_COMMON_TOKENS],
  [EventType.PortalVisitDispatched]: [...COMMON_TOKENS, ...TASK_VISIT_COMMON_TOKENS],
  [EventType.PortalVisitDueDateApproaching]: [...COMMON_TOKENS, ...TASK_VISIT_COMMON_TOKENS],
  [EventType.PortalOnTheWay]: [
    ...COMMON_TOKENS,
    ...TASK_VISIT_COMMON_TOKENS,
    {
      id: 'task.assignee.name',
      value: 'Assignee name',
      exampleValue: 'Robert Fox'
    },
    {
      id: 'task.assignee.phone',
      value: 'Assignee phone',
      exampleValue: '+1 (858) 444 4321'
    }
  ],
  [EventType.PortalArrived]: [
    ...COMMON_TOKENS,
    ...TASK_VISIT_COMMON_TOKENS,
    {
      id: 'task.assignee.name',
      value: 'Assignee name',
      exampleValue: 'Robert Fox'
    },
    {
      id: 'task.assignee.phone',
      value: 'Assignee phone',
      exampleValue: '+1 (858) 444 4321'
    }
  ],
  [EventType.PortalActionDueDateApproaching]: [...COMMON_TOKENS, ...ACTION_ITEM_COMMON_TOKENS],
  [EventType.PortalActionAssignedToContact]: [...COMMON_TOKENS, ...ACTION_ITEM_COMMON_TOKENS]
};
