import { useSelection } from '@components/Scheduler/useSelection';
import { ReactQueryKey } from '@enums';
import { ButtonSize, ButtonVariant, IconButton } from '@kit/ui/Button';
import { Edit } from 'react-feather';

import { PrivilegedTaskFilter, ReminderFilter } from '@generated/types/graphql';
import moment from 'moment';
import { XIcon } from '@kit/ui/icons/X';
import { Tooltip } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { DeepPartial } from 'redux';
import { useModal } from '@common/PromiseModal';
import { BulkEditWorkOrdersForm } from '@domains/workOrders';
import { Group } from './Group';
import { SortBy } from './SortBy';
import { Container, GroupList, Header, HeaderLeft, SelectionControl } from './styled';
import { ViewSwitcher } from './ViewSwitcher';

type TaskGroup = {
  title: string;
  filter: DeepPartial<PrivilegedTaskFilter>;
  initialExpanded?: boolean;
  remindersFilter?: DeepPartial<ReminderFilter>;
};

export const TaskGroups = () => {
  const queryClient = useQueryClient();
  const { openModal } = useModal();

  const { selected, checkIsSelected, toggle, clearSelection, selectedCount } = useSelection([]);

  const groups = useMemo<TaskGroup[]>(() => {
    const currentDate = moment();

    return [
      {
        title: 'Overdue',
        initialExpanded: true,
        filter: {
          isCompleted: { equalTo: false },
          or: [
            {
              endDate: {
                lessThan: currentDate.clone().startOf('day').toDate()
              }
            },
            {
              endDate: { isNull: true },
              startDate: {
                lessThan: currentDate.clone().startOf('day').toDate()
              }
            }
          ]
        },
        remindersFilter: {
          dueDate: {
            lessThan: currentDate.clone().startOf('day').toDate()
          }
        }
      },
      {
        title: 'This week',
        filter: {
          isCompleted: { equalTo: false },
          or: [
            {
              endDate: {
                lessThan: currentDate.clone().endOf('week').toDate(),
                greaterThanOrEqualTo: currentDate.clone().startOf('day').toDate()
              }
            },
            {
              endDate: { isNull: true },
              startDate: {
                lessThan: currentDate.clone().endOf('week').toDate(),
                greaterThanOrEqualTo: currentDate.clone().startOf('day').toDate()
              }
            }
          ]
        },
        remindersFilter: {
          dueDate: {
            lessThan: currentDate.clone().endOf('week').toDate(),
            greaterThanOrEqualTo: currentDate.clone().startOf('day').toDate()
          }
        }
      },
      {
        title: 'Next week',
        filter: {
          isCompleted: { equalTo: false },
          or: [
            {
              endDate: {
                lessThan: currentDate.clone().endOf('week').add(1, 'week').toDate(),
                greaterThan: currentDate.clone().startOf('week').add(1, 'week').toDate()
              }
            },
            {
              endDate: { isNull: true },
              startDate: {
                lessThan: currentDate.clone().endOf('week').add(1, 'week').toDate(),
                greaterThan: currentDate.clone().startOf('week').add(1, 'week').toDate()
              }
            }
          ]
        },
        remindersFilter: {
          dueDate: {
            lessThan: currentDate.clone().endOf('week').add(1, 'week').toDate(),
            greaterThan: currentDate.clone().startOf('week').add(1, 'week').toDate()
          }
        }
      },
      {
        title: 'Later',
        isCompleted: { equalTo: false },
        filter: {
          or: [
            {
              endDate: {
                greaterThan: currentDate.clone().endOf('week').add(1, 'week').toDate()
              }
            },
            {
              endDate: { isNull: true },
              startDate: {
                greaterThan: currentDate.clone().endOf('week').add(1, 'week').toDate()
              }
            }
          ]
        },
        remindersFilter: {
          dueDate: {
            greaterThan: currentDate.clone().endOf('week').add(1, 'week').toDate()
          }
        }
      },
      {
        title: 'Unscheduled',
        filter: {
          isCompleted: { equalTo: false },
          endDate: { isNull: true },
          startDate: { isNull: true }
        }
      },
      {
        title: 'Completed',
        filter: {
          isCompleted: { equalTo: true }
        }
      }
    ];
  }, []);

  const handleBulkEdit = useCallback(async () => {
    await openModal<void>(({ onClose }) => <BulkEditWorkOrdersForm workOrderIds={selected} onClose={onClose} />, {
      title: 'Bulk edit'
    });

    clearSelection();
    queryClient.invalidateQueries([ReactQueryKey.Tasks]);
    queryClient.invalidateQueries([ReactQueryKey.ProjectActivity]);
  }, [selected, openModal, clearSelection, queryClient]);

  return (
    <Container>
      <Header>
        <HeaderLeft>
          <SortBy />

          {selectedCount > 0 && (
            <SelectionControl>
              <IconButton onClick={clearSelection} variant={ButtonVariant.Secondary} size={ButtonSize.Small}>
                <XIcon size="16px" />
              </IconButton>

              <div>{selected.length} selected:</div>

              <Tooltip title="Edit">
                <IconButton onClick={handleBulkEdit} variant={ButtonVariant.Flat} size={ButtonSize.Small}>
                  <Edit size="16px" />
                </IconButton>
              </Tooltip>
            </SelectionControl>
          )}
        </HeaderLeft>
        <ViewSwitcher />
      </Header>

      <GroupList>
        {groups.map((group) => (
          <Group
            key={group.title}
            title={group.title}
            filter={group.filter}
            remindersFilter={group.remindersFilter}
            checkIsSelected={checkIsSelected}
            onToggleSelect={toggle}
            initialExpanded={group.initialExpanded}
          />
        ))}
      </GroupList>
    </Container>
  );
};
