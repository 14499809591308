export enum BulkEditType {
  SHIFT,
  REPLACE_COLLABORATORS,
  ADD_COLLABORATORS,
  REPLACE_ASSIGNEE,
  START_DATE,
  END_DATE,
  ARCHIVE
}

export const EDIT_TYPE_OPTIONS = [
  {
    id: BulkEditType.SHIFT,
    name: 'Shift start & due dates'
  },
  {
    id: BulkEditType.REPLACE_ASSIGNEE,
    name: 'Replace Assignee'
  },
  {
    id: BulkEditType.REPLACE_COLLABORATORS,
    name: 'Replace Collaborators'
  },
  {
    id: BulkEditType.ADD_COLLABORATORS,
    name: 'Add Collaborators'
  },
  {
    id: BulkEditType.START_DATE,
    name: 'Change start date'
  },
  {
    id: BulkEditType.END_DATE,
    name: 'Change due date'
  },
  {
    id: BulkEditType.ARCHIVE,
    name: 'Archive'
  }
];

export const SHIFT_DIRECTION_OPTIONS = [
  {
    id: 1,
    name: 'Forward'
  },
  {
    id: -1,
    name: 'Backward'
  }
];
