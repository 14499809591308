import { AssigneesField } from '@common/index';
import { useFormikContext } from 'formik';
import React from 'react';
import { User } from 'react-feather';
import { UserAvatar } from '@kit/components/UserAvatar';
import { useInlineUpdateField } from '../useInlineUpdateField';
import { AssigneesContainer, Placeholder, UserAvatarAndName } from './styled';
import { CommonFieldProps } from '../types';

interface MemberProps {
  assignee: {
    id: number;
    firstName: string;
    lastName: string;
    avatarUrl: string;
  };
}

const Member = ({ assignee }: MemberProps) => {
  // if this is custom property, not actual user
  if (!('avatarUrl' in assignee)) {
    return <div>{assignee.name}</div>;
  }

  return (
    <UserAvatarAndName>
      <UserAvatar user={assignee} size={20} />
      <div>
        {assignee.firstName} {assignee.lastName}
      </div>
    </UserAvatarAndName>
  );
};

export const AssigneeField = ({ onUpdated, setTeamCollaborators }: CommonFieldProps) => {
  const { isEdit, setIsEdit, setIsFocused, nodeRef } = useInlineUpdateField(onUpdated);

  const context = useFormikContext();

  if (isEdit) {
    return (
      <div ref={nodeRef}>
        <AssigneesField
          name="assignee"
          allowUserProperties
          membersOnly={false}
          isMultiple={false}
          allowTeams
          setTeamCollaborators={setTeamCollaborators}
          excludeIds={(context.values.assignees || []).map(({ id }) => id)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false);
          }}
        />
      </div>
    );
  }

  return (
    <AssigneesContainer onClick={() => setIsEdit(true)}>
      {context.values.assignee && <Member assignee={context.values.assignee} />}

      {!context.values.assignee && (
        <Placeholder>
          <User size="16px" /> Unassigned
        </Placeholder>
      )}
    </AssigneesContainer>
  );
};
