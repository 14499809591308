import { Team, User } from '@generated/types/graphql';
import { FormValidationRules, useForm, Form, InputField } from '@kit/components/Form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useTeamsMutations } from '@hooks/useTeams';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { UpdateTeamDTO } from '@types';
import { User as UserIcon, XCircle, Mail } from 'react-feather';
import { UserAvatar } from '@kit/components/UserAvatar';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { UserSelect } from './UserSelect';
import {
  Body,
  Email,
  Footer,
  MemberList,
  MemberRow,
  NameAndAvatar,
  MemberRowItems,
  Badge,
  UserName,
  UserDetails
} from './styled';

type FormValues = {
  name: string;
};

interface Props {
  initialValues?: Team;
  onClose: () => void;
}

export const TeamForm = ({ initialValues, onClose }: Props) => {
  const isNew = !initialValues;
  const [users, setUsers] = useState<User[]>(initialValues?.teamUsers ?? []);
  const [teamLeadId, setTeamLeadId] = useState<number | null>(initialValues?.teamLead?.id || null);

  const companyId = useAppSelector(selectWorkspaceId);
  const { create, update } = useTeamsMutations(companyId);

  useEffect(() => {
    // Add first user as team lead on create new team
    if (isNew && users.length === 1) {
      const [user] = users;

      setTeamLeadId(user.id);
    } else {
      // remove teamLeadId when lead was removed from the list (to disable buttons)
      const isTeamLeadInTheList = users.some((user) => user.id === teamLeadId);

      if (!isTeamLeadInTheList) {
        setTeamLeadId(null);
      }
    }
  }, [users, isNew, teamLeadId]);

  const postForm = async (values: FormValues) => {
    const dto: UpdateTeamDTO = {
      name: values.name,
      workers: users.map(({ id }) => id)
    };

    if (teamLeadId) {
      dto.teamLeadId = teamLeadId;
    }

    if (isNew) {
      await create.mutateAsync({ dto });
    } else {
      await update.mutateAsync({ id: initialValues.id, dto });
    }

    onClose();
  };

  const { form, handleSubmit } = useForm({
    onSubmit: postForm,
    defaultValues: initialValues ? { name: initialValues.name } : {}
  });

  const {
    formState: { isSubmitting },
    control
  } = form;

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      name: {
        isRequired: true
      }
    }),
    []
  );

  const handleAddUser = useCallback((user: User) => {
    setUsers((prev) => [user, ...prev]);
  }, []);

  const handleRemoveUser = useCallback(
    (user: User) => () => {
      setUsers((prev) => prev.filter((prevUser) => prevUser.id !== user.id));
    },
    []
  );

  const handleMakeUserTeamLead = useCallback((user: User) => () => setTeamLeadId(user.id), []);

  const getMenuItems = (user: User): MenuItem[] => {
    if (teamLeadId === user.id) {
      return [];
    }

    const menuItems: MenuItem[] = [
      {
        title: 'Remove',
        icon: <XCircle size="16px" color="#9C9CAA" />,
        onClick: handleRemoveUser(user)
      },
      {
        title: 'Make Team Lead',
        icon: <UserIcon size="16px" color="#9C9CAA" />,
        onClick: handleMakeUserTeamLead(user)
      }
    ];

    return menuItems;
  };

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Body>
        <InputField label="Team name" name="name" control={control} />

        <UserSelect selected={users} onSelect={handleAddUser} />

        <MemberList>
          {users.map((user) => {
            const menuItems = getMenuItems(user);
            const isTeamLead = teamLeadId === user.id;

            return (
              <MemberRow key={user.id}>
                <NameAndAvatar>
                  <UserDetails>
                    <UserName>
                      <UserAvatar user={user} size={20} />
                      {user.firstName} {user.lastName}
                    </UserName>

                    <Email>
                      <Mail color="#828D9A" width={16} height={16} />
                      {user.email || user.phone}
                    </Email>
                  </UserDetails>
                </NameAndAvatar>

                <MemberRowItems>
                  {isTeamLead && <Badge>Team Lead</Badge>}

                  {menuItems.length > 0 && <ContextMenu items={menuItems} />}
                </MemberRowItems>
              </MemberRow>
            );
          })}
        </MemberList>
      </Body>
      <Footer>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting || !users.length} variant={ButtonVariant.Primary} type="submit">
          {isNew ? 'Create' : 'Update'}
        </Button>
      </Footer>
    </Form>
  );
};
