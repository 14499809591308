import { TabItem } from '@kit/ui/Tabs';

export const TEMPLATE_TABS: TabItem[] = [
  { title: 'Email', id: 'email' },
  { title: 'SMS', id: 'sms' }
];

export const TIME_UNIT_OPTIONS = [
  { label: 'hours before', value: 'HOURS' },
  { label: 'days before', value: 'DAYS' }
];

const generateTimeOptions = () => {
  const options = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const time = new Date(2000, 0, 1, hour, minute);

      // Format the label (12-hour format)
      const label = time
        .toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        })
        .replace(/\s/, ' '); // Ensure consistent spacing

      // Format the value (24-hour format)
      const value = time.toLocaleTimeString('en-US', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });

      options.push({ label, value });
    }
  }

  return options;
};

export const TIME_OPTIONS = generateTimeOptions();
export const DEFAULT_TIME_OPTION = { label: '8:30 AM', value: '08:30:00' };
