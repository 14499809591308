import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
`;

export const SettingsPanelToggle = styled.div`
  flex: 0 0 50px;
  text-align: center;
`;

export const SettingsPanelContent = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SettingsPanelHeader = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  justify-content: space-between;
`;

export const SettingsPanelTitle = styled.h3<{ isDisabled?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 4px;
  color: ${({ isDisabled }) => (isDisabled ? '#828d9a' : '#000')};
`;

export const SettingsPanelSecondaryText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #828d9a;
`;
