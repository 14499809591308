import { ModalBody, ModalFooter } from '@common/PromiseModal';
import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { RadioGroup } from '@kit/ui/RadioGroup';
import { TaskTemplate } from '@types';
import React, { useCallback, useState } from 'react';
import { Description } from './styled';

interface Props {
  workOrderTemplate: TaskTemplate;
  onClose: (result: 'archive' | 'none' | void) => void;
}

const OPTIONS = [
  { value: 'none', label: 'Keep existing Work Orders' },
  { value: 'archive', label: 'Archive existing Work Orders' }
];

export const ConfirmRemove = ({ workOrderTemplate, onClose }: Props) => {
  const [selectedOption, setSelectedOption] = useState<'archive' | 'none'>('none');
  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleApply = useCallback(() => {
    onClose(selectedOption);
  }, [onClose, selectedOption]);

  return (
    <>
      <ModalBody>
        <Description>
          You are about to remove{' '}
          <b>
            <WorkOrderTypeIcon isField={workOrderTemplate.isField} /> {workOrderTemplate.title}
          </b>{' '}
          from this Workflow.
          <br />
          What would you like to do with existing Work Orders in Projects?
          <br />
          <br />
        </Description>
        <RadioGroup
          name="approach"
          options={OPTIONS}
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleCancel} variant={ButtonVariant.Secondary}>
          Cancel
        </Button>
        <Button onClick={handleApply} variant={ButtonVariant.Primary}>
          Apply
        </Button>
      </ModalFooter>
    </>
  );
};
