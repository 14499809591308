import React, { memo, useState } from 'react';
import { find } from 'lodash';
import { AutomationStepType } from '@enums';
import { useAutomationTriggersAndActions } from '@hooks/automations/useAutomationTriggersAndActions';
import { AutomationFromApi, TriggerStep } from '@types';
import { useCompanyProperties } from '@hooks/useCompanyProperties';
import { RecordTypeProvider } from '@features/Platform/Automations/Form/useRecordType';
import { TriggerForm } from './TriggerForm';
import { NameAndActive } from './NameAndActive';
import { ActionForm } from './ActionForm';
import { LongArrow } from './Arrow';
import { ArrowContainer } from './styled';

interface Props {
  initialValues?: AutomationFromApi & { context?: 'workOrderTemplate' | 'projectStage' };
  onCreated?: (automation: AutomationFromApi) => void;
}

export const Form = memo(({ initialValues, onCreated }: Props) => {
  const isNew = !initialValues?.id;

  const [selectedTrigger, setSelectedTrigger] = useState<TriggerStep | undefined>(undefined);
  const { allProperties } = useCompanyProperties();

  const { isLoading } = useAutomationTriggersAndActions();

  const isPropertiesLoading = !allProperties?.length;

  const triggerStep = find(initialValues?.steps, { type: AutomationStepType.TRIGGER });
  const actionStep = find(initialValues?.steps, { type: AutomationStepType.ACTION });
  const triggerId = triggerStep?.id;
  if (isLoading || isPropertiesLoading) {
    return null;
  }

  return (
    <div>
      <NameAndActive onCreated={onCreated} initialValues={initialValues} />

      {!isNew && (
        <RecordTypeProvider>
          <TriggerForm
            context={initialValues?.context}
            automationId={initialValues?.id}
            step={triggerStep}
            onTriggerChange={setSelectedTrigger}
          />
          <ArrowContainer>
            <LongArrow />
          </ArrowContainer>
          {selectedTrigger && (
            <ActionForm
              triggerId={triggerId}
              trigger={selectedTrigger}
              automationId={initialValues?.id}
              step={actionStep}
            />
          )}
        </RecordTypeProvider>
      )}
    </div>
  );
});
