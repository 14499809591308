import styled from 'styled-components';
import { SettingsPanel } from '../styled';

export const Container = styled(SettingsPanel)`
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

export const Separator = styled.div`
  height: 1px;
  background-color: #dfdfe8;
  margin: 24px 0;
`;
