import { ModalBody, ModalFooter } from '@common/PromiseModal';
import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { RadioGroup } from '@kit/ui/RadioGroup';
import React, { useCallback, useMemo, useState } from 'react';
import { useAppSelector } from '@hooks/index';
import { selectCurrentUser } from '@state/selectors';
import { Checkbox } from '@kit/ui/Checkbox';
import { usePreCreateWorkflowWorkOrderInfo } from '@hooks/workflows/usePreCreateWorkflowWorkOrderInfo';
import { useStageList } from '@hooks/workspace/stages/useStageList';
import { Caption, Description } from './styled';

export enum ConfirmAddResult {
  Create = 'create',
  CreateCompleted = 'createCompleted',
  CreateCancelled = 'createCancelled'
}

interface Props {
  workflowId: number;

  workOrderTemplate: { id: number; title: string; isField: boolean };
  stage: { id: number; name: string };
  workflowStageId: number;
  sliceId?: number;
  onClose: (result: { action: ConfirmAddResult; addToFeed: boolean } | void) => void;
}

const OPTIONS = [
  { value: ConfirmAddResult.Create, label: 'Create Work Order(s)' },
  { value: ConfirmAddResult.CreateCompleted, label: 'Create Work Order(s) & update their Status to COMPLETED' },
  { value: ConfirmAddResult.CreateCancelled, label: 'Create Work Order(s) & update their Status to CANCELLED' }
];

export const ConfirmAdd = ({ workflowId, workflowStageId, sliceId, workOrderTemplate, stage, onClose }: Props) => {
  const currentUser = useAppSelector(selectCurrentUser);

  const { data: stages, isLoading: isStagesLoading } = useStageList();

  const { data: preCreateInfo, isLoading: isLoadingInfo } = usePreCreateWorkflowWorkOrderInfo({
    workflowId,
    workflowStageId,
    sliceId,
    workOrderId: workOrderTemplate.id
  });

  const [addToFeed, setAddToFeed] = useState(true);

  const isCoperniqUser = currentUser?.email?.endsWith('@coperniq.io');

  const [selectedOption, setSelectedOption] = useState<ConfirmAddResult>(ConfirmAddResult.Create);
  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleApply = useCallback(() => {
    onClose({ action: selectedOption, addToFeed });
  }, [onClose, selectedOption, addToFeed]);

  const isLoading = isLoadingInfo || isStagesLoading;

  const hasAnyAffectedProjects = preCreateInfo?.affectedProjects.some((project) => project.count > 0);

  const info = useMemo(() => {
    if (!preCreateInfo || !stages) {
      return null;
    }

    if (!hasAnyAffectedProjects) {
      return `There are no active Projects in Stage "${stage.name}", that will be affected by this change.`;
    }

    if (preCreateInfo.affectedStages.length === 1) {
      return `There is ${preCreateInfo.affectedProjects[0].count} active Project in Stage "${stage.name}". How do you want to proceed with it?`;
    }

    const affectedStageList = preCreateInfo.affectedStages
      .map(
        (affectedStageId) =>
          preCreateInfo.affectedProjects.find(({ stageId, count }) => affectedStageId === stageId && count > 0) &&
          stages.find((stage) => stage.id === affectedStageId)?.name
      )
      .filter(Boolean);

    const isSingleStage = affectedStageList.length === 1;

    const totalCount = preCreateInfo.affectedProjects.reduce((acc, project) => acc + project.count, 0);

    const isSingleProject = totalCount === 1;

    return (
      <>
        There {isSingleProject ? 'is' : 'are'} {totalCount} active Project{isSingleProject ? '' : 's'} in Stage
        {isSingleStage ? '' : 's'} {affectedStageList.join(', ')}, that will be affected by this change.
        <br />
        How do you want to proceed with {isSingleProject ? 'it' : 'them'}?
      </>
    );
  }, [preCreateInfo, stages, hasAnyAffectedProjects, stage.name]);

  return (
    <>
      <ModalBody width="700px">
        <Description>
          You are about to add{' '}
          <b>
            <WorkOrderTypeIcon isField={workOrderTemplate.isField} /> {workOrderTemplate.title}
          </b>{' '}
          to this Workflow.
          <br />
          {isLoading && 'Fetching information about existing Projects...'}
          {!isLoading && info}
          <br />
          <br />
        </Description>
        {hasAnyAffectedProjects && (
          <RadioGroup
            name="approach"
            options={OPTIONS}
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
            renderOptionLabel={(option) => (
              <div>
                {option.label}
                {option.value === ConfirmAddResult.Create && (
                  <Caption>
                    These Work Orders will block Projects advancement for the Stages they were added in.
                  </Caption>
                )}
              </div>
            )}
          />
        )}

        {isCoperniqUser && (
          <>
            <br />
            <br />
            <Checkbox
              label="Create Project Feed event about creating this Work Order"
              isChecked={addToFeed}
              onChange={setAddToFeed}
            />
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleCancel} variant={ButtonVariant.Secondary}>
          Cancel
        </Button>
        <Button onClick={handleApply} variant={ButtonVariant.Primary}>
          Apply
        </Button>
      </ModalFooter>
    </>
  );
};
