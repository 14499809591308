import React, { useCallback, useMemo, useState } from 'react';
import { Button, ButtonVariant, IconButton } from '@kit/ui/Button';
import { CheckboxField, Form, FormValidationRules, InputField, SelectField, useForm } from '@kit/components/Form';
import {
  UpdateCompanyNotificationPayload,
  useUpdateCompanyNotification
} from '@hooks/workspace/notifications/useUpdateCompanyNofification';
import { PortalNotificationEvent } from '@hooks/workspace/notifications/usePortalNotifications';
import { Plus } from 'react-feather';
import { TrashIcon } from '@kit/ui/icons/Trash';
import {
  clearQuillValue,
  clearQuillValueForSms,
  convertQuillFormatToText,
  convertTextToQuillFormat,
  isQuilValueEmpty
} from '@utils/quill';
import { ModalBody, ModalFooter } from '@common/PromiseModal';
import { Collapse } from '@material-ui/core';
import { useDeepCompareEffect } from '@react-hookz/web';
import { Asterisk, CollapsibleReminder, FormFieldLabel, ReminderSettingRow, TimeSelectContainer } from './styled';
import { DEFAULT_TIME_OPTION, TIME_OPTIONS, TIME_UNIT_OPTIONS } from './constants';
import { TemplateContentEditor } from './TemplateContentEditor';

interface FormValues {
  offset1Time: number;
  offset1SpecifiedTime: { label: string; value: string };
  offset1Unit: { label: string; value: string };
  offset1Email: boolean;
  offset1Sms: boolean;
  offset2Time: number;
  offset2SpecifiedTime: { label: string; value: string };
  offset2Unit: { label: string; value: string };
  offset2Email: boolean;
  offset2Sms: boolean;
  offset1EmailNotificationSubject: string;
  offset1EmailNotificationText: string;
  offset1SmsNotificationText: string;
  offset2EmailNotificationSubject: string;
  offset2EmailNotificationText: string;
  offset2SmsNotificationText: string;
}

const getDefaultValues = (event: PortalNotificationEvent) => {
  return {
    offset1Time: event.settings.offset1Time,
    offset1SpecifiedTime:
      TIME_OPTIONS.find((option) => option.value === event.settings.offset1SpecifiedTime) ?? DEFAULT_TIME_OPTION,
    offset1Unit:
      TIME_UNIT_OPTIONS.find((option) => option.value === event.settings.offset1Unit) ?? TIME_UNIT_OPTIONS[0],
    offset1Email: event.settings.offset1Email ?? false,
    offset1Sms: event.settings.offset1Sms ?? false,
    offset1EmailNotificationSubject: convertTextToQuillFormat(event.settings.emailNotificationSubject),
    offset1EmailNotificationText: convertTextToQuillFormat(event.settings.emailNotificationText),
    offset1SmsNotificationText: convertTextToQuillFormat(event.settings.smsNotificationText),

    offset2Time: event.settings.offset2Time,
    offset2SpecifiedTime:
      TIME_OPTIONS.find((option) => option.value === event.settings.offset2SpecifiedTime) ?? DEFAULT_TIME_OPTION,
    offset2Unit:
      TIME_UNIT_OPTIONS.find((option) => option.value === event.settings.offset2Unit) ?? TIME_UNIT_OPTIONS[0],
    offset2Email: event.settings.offset2Email ?? false,
    offset2Sms: event.settings.offset2Sms ?? false,
    offset2EmailNotificationSubject: convertTextToQuillFormat(event.settings.offset2EmailNotificationSubject),
    offset2EmailNotificationText: convertTextToQuillFormat(event.settings.offset2EmailNotificationText),
    offset2SmsNotificationText: convertTextToQuillFormat(event.settings.offset2SmsNotificationText)
  };
};
interface Props {
  event: PortalNotificationEvent;
  onClose: () => void;
}

const quillRequiredValidateFn = (value: string) => {
  if (isQuilValueEmpty(value)) {
    return 'This is required';
  }

  return undefined;
};

export const ScheduledEventForm = ({ event, onClose }: Props) => {
  const isSecondReminderShownInitial = event.settings.offset2Email || event.settings.offset2Sms;
  const [isSecondReminderShown, setIsSecondReminderShown] = useState(isSecondReminderShownInitial);

  const [isReminder1Collapsed, setIsReminder1Collapsed] = useState(isSecondReminderShownInitial);
  const [isReminder2Collapsed, setIsReminder2Collapsed] = useState(isSecondReminderShownInitial);

  const { mutateAsync: update } = useUpdateCompanyNotification();

  const postForm = async (values: FormValues) => {
    const payload: UpdateCompanyNotificationPayload = {
      entityType: event.settings.entityType,
      eventType: event.settings.eventType,
      emailEnabled: values.offset1Email || values.offset2Email,
      smsEnabled: values.offset1Sms || values.offset2Sms,
      offset1Time: values.offset1Time,
      offset1SpecifiedTime: values.offset1SpecifiedTime?.value ?? null,
      offset1Unit: values.offset1Unit.value,
      offset1Email: values.offset1Email ?? false,
      offset1Sms: values.offset1Sms ?? false,
      emailNotificationSubject: clearQuillValue(convertQuillFormatToText(values.offset1EmailNotificationSubject)),
      emailNotificationText: convertQuillFormatToText(values.offset1EmailNotificationText),
      smsNotificationText: clearQuillValueForSms(convertQuillFormatToText(values.offset1SmsNotificationText)),

      offset2Time: null,
      offset2SpecifiedTime: null,
      offset2Unit: null,
      offset2Email: false,
      offset2Sms: false,
      offset2EmailNotificationSubject: '',
      offset2EmailNotificationText: '',
      offset2SmsNotificationText: ''
    };

    if (isSecondReminderShown) {
      payload.offset2Time = values.offset2Time;
      payload.offset2SpecifiedTime = values.offset2SpecifiedTime?.value ?? null;
      payload.offset2Unit = values.offset2Unit.value;
      payload.offset2Email = values.offset2Email ?? false;
      payload.offset2Sms = values.offset2Sms ?? false;
      payload.offset2EmailNotificationSubject = clearQuillValue(
        convertQuillFormatToText(values.offset2EmailNotificationSubject)
      );
      payload.offset2EmailNotificationText = convertQuillFormatToText(values.offset2EmailNotificationText);
      payload.offset2SmsNotificationText = clearQuillValueForSms(
        convertQuillFormatToText(values.offset2SmsNotificationText)
      );
    }

    await update(payload);

    onClose();
  };

  const { form, handleSubmit } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: getDefaultValues(event)
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { control, reset, watch } = form;

  const rules = useMemo<FormValidationRules<FormValues>>(() => {
    return {
      offset1Time: {
        min: 1
      },
      offset1SpecifiedTime: {
        isRequired: true
      },
      offset1EmailNotificationSubject: {
        isRequired: true,
        validate: quillRequiredValidateFn
      },
      offset1EmailNotificationText: {
        isRequired: true,
        validate: quillRequiredValidateFn
      },
      offset1SmsNotificationText: {
        isRequired: true,
        validate: quillRequiredValidateFn
      },
      offset2Time: {
        min: 1
      },
      offset2SpecifiedTime: {
        isRequired: true
      },
      offset2EmailNotificationSubject: {
        isRequired: true,
        validate: quillRequiredValidateFn
      },
      offset2EmailNotificationText: {
        isRequired: true,
        validate: quillRequiredValidateFn
      },
      offset2SmsNotificationText: {
        isRequired: true,
        validate: quillRequiredValidateFn
      }
    };
  }, []);

  const handleAddSecondReminder = useCallback(() => {
    setIsSecondReminderShown(true);
    setIsReminder1Collapsed(true);
    setIsReminder2Collapsed(false);

    reset((prev) => ({
      ...prev,
      offset2Time: 1,
      offset2SpecifiedTime: DEFAULT_TIME_OPTION,
      offset2Unit: TIME_UNIT_OPTIONS[0],
      offset2Email: true,
      offset2Sms: false,
      offset2EmailNotificationSubject: event.eventSchema.emailTemplate?.subject ?? '',
      offset2EmailNotificationText: event.eventSchema.emailTemplate?.body ?? '',
      offset2SmsNotificationText: event.eventSchema.smsTemplate ?? ''
    }));
  }, [reset, event]);

  const handleRemoveSecondReminder = useCallback(() => {
    setIsSecondReminderShown(false);
    setIsReminder1Collapsed(false);
  }, []);

  const [offset1Unit, offset2Unit] = watch(['offset1Unit', 'offset2Unit']);

  useDeepCompareEffect(() => {
    if (offset1Unit.value === 'DAYS') {
      reset((prev) => ({
        ...prev,
        offset1SpecifiedTime: prev.offset1SpecifiedTime ?? DEFAULT_TIME_OPTION
      }));
    }
  }, [reset, offset1Unit]);

  useDeepCompareEffect(() => {
    if (offset2Unit?.value === 'DAYS') {
      reset((prev) => ({
        ...prev,
        offset2SpecifiedTime: prev.offset2SpecifiedTime ?? DEFAULT_TIME_OPTION
      }));
    }
  }, [reset, offset2Unit]);

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <ModalBody width="840px">
        <FormFieldLabel>
          Schedules
          <Asterisk>*</Asterisk>
        </FormFieldLabel>

        {!isSecondReminderShown && (
          <>
            <ReminderSettingRow>
              <div>Send a reminder</div>
              <InputField name="offset1Time" type="number" control={control} />
              <SelectField
                name="offset1Unit"
                options={TIME_UNIT_OPTIONS}
                control={control}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                isClearable={false}
              />
              <div>the appointment by</div>
              <CheckboxField name="offset1Email" label="Email" control={control} />
              <CheckboxField name="offset1Sms" label="SMS" control={control} />
              {offset1Unit.value === 'DAYS' && (
                <>
                  <div>at</div>
                  <TimeSelectContainer>
                    <SelectField
                      name="offset1SpecifiedTime"
                      options={TIME_OPTIONS}
                      control={control}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      isClearable={false}
                    />
                  </TimeSelectContainer>
                </>
              )}
            </ReminderSettingRow>

            <div onClick={handleAddSecondReminder}>
              <Button variant={ButtonVariant.Flat}>
                <Plus size={16} />
                Add reminder
              </Button>
            </div>

            <TemplateContentEditor
              control={control}
              emailSubjectFieldName="offset1EmailNotificationSubject"
              emailTextFieldName="offset1EmailNotificationText"
              smsTextFieldName="offset1SmsNotificationText"
              eventType={event.settings.eventType}
              watch={watch}
            />
          </>
        )}

        {isSecondReminderShown && (
          <>
            <CollapsibleReminder>
              <ReminderSettingRow>
                <div>Send a reminder</div>
                <InputField name="offset1Time" type="number" control={control} />
                <SelectField
                  name="offset1Unit"
                  options={TIME_UNIT_OPTIONS}
                  control={control}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  isClearable={false}
                />
                <div>the appointment by</div>
                <CheckboxField name="offset1Email" label="Email" control={control} />
                <CheckboxField name="offset1Sms" label="SMS" control={control} />
                {offset1Unit.value === 'DAYS' && (
                  <>
                    <div>at</div>
                    <TimeSelectContainer>
                      <SelectField
                        name="offset1SpecifiedTime"
                        options={TIME_OPTIONS}
                        control={control}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        isClearable={false}
                      />
                    </TimeSelectContainer>
                  </>
                )}
              </ReminderSettingRow>

              <Button
                isUpperCase={false}
                variant={ButtonVariant.Flat}
                onClick={() => setIsReminder1Collapsed((prev) => !prev)}
              >
                {isReminder1Collapsed ? 'Edit message' : 'Hide details'}
              </Button>

              <Collapse in={!isReminder1Collapsed}>
                <TemplateContentEditor
                  control={control}
                  emailSubjectFieldName="offset1EmailNotificationSubject"
                  emailTextFieldName="offset1EmailNotificationText"
                  smsTextFieldName="offset1SmsNotificationText"
                  eventType={event.settings.eventType}
                  watch={watch}
                />
              </Collapse>
            </CollapsibleReminder>
            <CollapsibleReminder>
              <ReminderSettingRow>
                <div>Send a reminder</div>
                <InputField name="offset2Time" type="number" control={control} />
                <SelectField
                  name="offset2Unit"
                  options={TIME_UNIT_OPTIONS}
                  control={control}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  isClearable={false}
                />
                <div>the appointment by</div>
                <CheckboxField name="offset2Email" label="Email" control={control} />
                <CheckboxField name="offset2Sms" label="SMS" control={control} />
                {offset2Unit.value === 'DAYS' && (
                  <>
                    <div>at</div>
                    <TimeSelectContainer>
                      <SelectField
                        name="offset2SpecifiedTime"
                        options={TIME_OPTIONS}
                        control={control}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        isClearable={false}
                      />
                    </TimeSelectContainer>
                  </>
                )}
                <IconButton onClick={handleRemoveSecondReminder} variant={ButtonVariant.Flat}>
                  <TrashIcon color="#D54855" size={16} />
                </IconButton>
              </ReminderSettingRow>

              <Button
                isUpperCase={false}
                variant={ButtonVariant.Flat}
                onClick={() => setIsReminder2Collapsed((prev) => !prev)}
              >
                {isReminder2Collapsed ? 'Edit message' : 'Hide details'}
              </Button>
              <Collapse in={!isReminder2Collapsed}>
                <TemplateContentEditor
                  control={control}
                  emailSubjectFieldName="offset2EmailNotificationSubject"
                  emailTextFieldName="offset2EmailNotificationText"
                  smsTextFieldName="offset2SmsNotificationText"
                  eventType={event.settings.eventType}
                  watch={watch}
                />
              </Collapse>
            </CollapsibleReminder>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose} variant={ButtonVariant.Secondary}>
          Cancel
        </Button>
        <Button type="submit" variant={ButtonVariant.Primary}>
          Update
        </Button>
      </ModalFooter>
    </Form>
  );
};
