import React, { useCallback, useMemo, useState } from 'react';

import { TabItem, Tabs } from '@kit/ui/Tabs';
import { ContextMenu } from '@kit/components/ContextMenu';
import { Clipboard, FileText, Plus } from 'react-feather';
import { useNavigate } from '@reach/router';
import { SearchBar } from '@common/SearchBar';
import { QueryParamsEnum, useQueryParam, useQueryParamMutation } from '@hooks/useQueryParam';
import { XIcon } from '@kit/ui/icons/X';
import { WorkOrderTemplateForm } from '@components/templates/Workspace/Templates/TaskTemplates/TaskTemplateForm/WorkOrderTemplateForm';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { FileUpIcon } from '@kit/ui/icons/FileUp';
import { useAppSelector } from '@hooks/store';
import { isCustomerPortalEnabled } from '@state/selectors';
import { WorkOrders } from './WorkOrders';
import {
  CloseButton,
  Container,
  Description,
  Header,
  HeaderLeft,
  OuterButtons,
  PageHeader,
  RightSliderPanel,
  TableContainer,
  Title
} from './styled';
import { Forms } from './Forms';
import { ActionItems } from './ActionItems';

enum TemplateTab {
  WORK_ORDERS = 'workOrders',
  FORMS = 'forms',
  ACTION_ITEMS = 'actionItemTemplates'
}

interface Props {
  entity: TemplateTab;
}

export const List = ({ entity }: Props) => {
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const { setParams } = useQueryParamMutation();
  const [selectedWorkOrderTemplateId, setSelectedWorkOrderTemplateId] = useQueryParam(
    QueryParamsEnum.WorkOrderTemplateId
  );
  const [fromId] = useQueryParam(QueryParamsEnum.FromId);

  const isPortalEnabled = useAppSelector(isCustomerPortalEnabled);

  const { openDrawer } = useDrawersContext();

  const tabs = useMemo<TabItem[]>(() => {
    const result: TabItem[] = [
      {
        id: TemplateTab.WORK_ORDERS,
        title: 'Work Orders'
      },
      {
        id: TemplateTab.FORMS,
        title: 'Forms'
      }
    ];

    if (isPortalEnabled) {
      result.push({
        id: TemplateTab.ACTION_ITEMS,
        title: 'Action Items'
      });
    }

    return result;
  }, [isPortalEnabled]);

  const selectedTab = useMemo(() => tabs.find((tab) => tab.id === entity) ?? tabs[0], [tabs, entity]);

  const newTemplateMenuItems = useMemo(() => {
    const result = [
      {
        icon: <Clipboard size="16px" color="#9C9CAA" />,
        title: 'Work Order',
        onClick: () => {
          setSelectedWorkOrderTemplateId('new');
        }
      },
      {
        icon: <FileText size="16px" color="#9C9CAA" />,
        title: 'Form',
        onClick: () => {
          navigate('../forms/new');
        }
      }
    ];

    if (isPortalEnabled) {
      result.push({
        icon: <FileUpIcon size="16px" color="#9C9CAA" />,
        title: 'Action Item',
        onClick: () => {
          openDrawer(DrawerEntity.ACTION_ITEM_TEMPLATE, 0, []);
        }
      });
    }

    return result;
  }, [navigate, setSelectedWorkOrderTemplateId, openDrawer, isPortalEnabled]);

  const handleCloseTemplate = useCallback(() => {
    setParams({
      [QueryParamsEnum.WorkOrderTemplateId]: undefined,
      [QueryParamsEnum.ActionItemTemplateId]: undefined,
      [QueryParamsEnum.FromId]: undefined
    });
  }, [setParams]);

  const handleTabChange = useCallback(
    (tab: TabItem) => {
      navigate(`../${tab.id}`);
    },
    [navigate]
  );

  return (
    <Container>
      <PageHeader>
        <Title>Templates</Title>
        <Description>Configure templates for Work orders, Forms and Actions</Description>
      </PageHeader>
      <Header>
        <HeaderLeft>
          <ContextMenu items={newTemplateMenuItems}>
            <Button variant={ButtonVariant.Primary}>
              <Plus size="16px" />
              Template
            </Button>
          </ContextMenu>
          <SearchBar placeholder="Search..." onValueChange={setSearch} />
        </HeaderLeft>
        <Tabs tabs={tabs} selected={selectedTab.id} onChange={handleTabChange} />
      </Header>

      <TableContainer>
        {selectedTab.id === TemplateTab.WORK_ORDERS && <WorkOrders search={search} />}

        {selectedTab.id === TemplateTab.FORMS && <Forms search={search} />}

        {selectedTab.id === TemplateTab.ACTION_ITEMS && <ActionItems search={search} />}
      </TableContainer>

      {selectedWorkOrderTemplateId && (
        <RightSliderPanel>
          <OuterButtons>
            <CloseButton onClick={handleCloseTemplate}>
              <XIcon size="24px" />
            </CloseButton>
          </OuterButtons>

          <WorkOrderTemplateForm
            cloneFromId={fromId ? +fromId : undefined}
            id={selectedWorkOrderTemplateId === 'new' ? selectedWorkOrderTemplateId : +selectedWorkOrderTemplateId}
            onClose={handleCloseTemplate}
          />
        </RightSliderPanel>
      )}
    </Container>
  );
};
