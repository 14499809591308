import React, { useCallback, useMemo, useState } from 'react';
import {
  AutomationFromApi,
  BlueprintDTO,
  BlueprintProjectStageDTO,
  BlueprintTaskDTO,
  Form,
  RecordType,
  Routable,
  TaskTemplate,
  WorkspaceStageFromAPI
} from '@types';
import {
  AddSlice,
  AutomationFormContainer,
  BlueprintFlow,
  BlueprintProjectStageTitle,
  BlueprintSliceTasks,
  BlueprintStage,
  BlueprintStageSlices,
  BlueprintStageSlicesWrapper,
  BlueprintStageTitleWrapper,
  ProjectStageAutomationsZap,
  SlidePanelContainer,
  TimesliceQuestionMark
} from '@components/templates/Workspace/Workflows/styled';
import { find, flatten, sortBy, values } from 'lodash';
import { groupBy } from 'lodash/fp';
import { useBlueprintMutations, useBlueprintRest } from '@hooks/useBlueprints';
import { InfoPanel } from '@components/templates/common/InfoPanel';
import { Actions } from '@components/templates/Workspace/Workflows/common/Button/styled';
import { useProjectStagesRest } from '@hooks/useProjectStages';

import { useWorkflow } from '@components/templates/Workspace/Automations/hooks';
import { colors } from '@styles';
import { ChevronLeft, ChevronRight, HelpCircle, Zap } from 'react-feather';
import { TooltipWrapper } from '@components/templates/common/InfoPanel/styled';
import { Tooltip } from '@material-ui/core';
import { ProjectStageBadge } from '@components/ProjectStages';
import { AutomationStepType, Trigger } from '@enums';
import { XIcon } from '@kit/ui/icons/X';
import { FormBuilderContainer } from '@features/Platform/Templates/FormBuilder/FormBuilder';
import { useDrawerOpenState } from '@components/LayoutNew/MainMenu/useDrawerOpenState';
import { Link } from 'gatsby';
import { ChevronRightIcon } from '@kit/ui/icons/ChevronRight';
import { useWorkOrderTemplateList } from '@hooks/templates/workOrders/useWorkOrderTemplateList';
import { AutomationForm } from '@features/Platform/Automations/Form';
import { Button } from './common/Button';
import { BlueprintFakeTask, BlueprintTask, BlueprintTaskPlaceholder } from './BlueprintTask';
// import { AutomationForm } from '../Workflows/WorkflowBuilder/AutomationForm';
import { AddAutomationButton, Breadcrumbs, CloseButton, FormSlidePanel, OuterButtons } from './styled';
import { WorkOrderTemplateForm } from '../Templates/TaskTemplates/TaskTemplateForm/WorkOrderTemplateForm';
import { useConfirmAddWO } from './useConfirmAddWO';

let fakeId = -1;

const initialValueTaskModal: Partial<TaskTemplate> = {
  id: 'new',
  title: '',
  position: 0,
  priority: 2,
  description: '',
  templateUsers: [],
  subTasks: [],
  labels: [],
  forms: [],
  startDate: undefined,
  endDate: undefined,
  isColored: false,
  isTemplate: true,
  isField: true
};

/**
 * X axis = project stages, Y axis = timeslices, cells = tasks of a timeslice created on a stage.
 */
type TasksTable = { sliceId: number | null; tasks: BlueprintTaskDTO[] }[][];

/**
 * Pads task chains in stages with fake task chains, so tasks align in waterflow-like ladder with pure css grid.
 * Padding is done by filling all gaps in tasks table  with fake invisible tasks.
 *
 * To debug and understand better, set opacity from BlueprintTask's 'fake' property to >0, and look at fakes alignment.
 */
const getTasksTable = (blueprint?: BlueprintDTO): TasksTable => {
  if (!blueprint) {
    return [];
  }

  const anyTask = blueprint.projectStages.reduce<BlueprintTaskDTO | null>(
    (found, stage) => found || stage.blueprintTasks[0],
    null
  );

  // eslint-disable-next-line no-return-assign
  const makeFake = (): BlueprintTaskDTO => ({
    ...anyTask,
    id: (fakeId -= 1),
    task: { ...anyTask?.task, title: '' }
  });

  const slicesOrder = sortBy(blueprint.slices, 'position').map(({ id }) => id);

  return sortBy(blueprint.projectStages, 'position').map((stage) => {
    const tasksBySlice: { [id: number]: BlueprintTaskDTO[] } = groupBy('sliceId')(stage.blueprintTasks);

    const maxTasks = Math.max(1, values(tasksBySlice).reduce((acc, tasks) => Math.max(tasks.length, acc), 0) + 1);

    return slicesOrder
      .map((sliceId) => {
        const sliceTasks = tasksBySlice[sliceId] || [];

        return {
          sliceId,
          tasks: sliceTasks
            .sort((taskA, taskB) => new Date(taskA.createdAt).getTime() - new Date(taskB.createdAt).getTime())
            .concat(Array.from({ length: maxTasks - sliceTasks.length }).map(makeFake))
        };
      })
      .concat([{ sliceId: null, tasks: Array.from({ length: maxTasks }).map(makeFake) }]);
  });
};

interface BlueprintProps extends Routable {
  blueprintId: number;
}

const Blueprint: React.FC<BlueprintProps> = ({ blueprintId }) => {
  const [isDrawerOpen] = useDrawerOpenState();

  const {
    fetch: { data: blueprint }
  } = useBlueprintRest(blueprintId);

  const [selectedWorkOrderTemplate, setSelectedWorkOrderTemplate] = useState<BlueprintTaskDTO | null>(null);
  const [selectedAutomation, setSelectedAutomation] = useState<AutomationFromApi | null>(null);
  const [selectedForm, setSelectedForm] = useState<Form | null>(null);

  const [awatingSliceForNewTemplate, setAwatingSliceForNewTemplate] = useState<{
    sliceId: number | undefined;
    stageId: number;
    projectStage: WorkspaceStageFromAPI;
  } | null>(null);
  const {
    createProjectStage,
    deleteProjectStage,
    createTask: { mutateAsync: bindTaskToWorkflow }
  } = useBlueprintMutations(blueprintId);

  const {
    fetchAll: { data: workspaceProjectStages }
  } = useProjectStagesRest();

  const { data: taskTemplates } = useWorkOrderTemplateList();

  const confirmAddWO = useConfirmAddWO(blueprintId);

  const taskTemplatesById = useMemo(() => {
    if (!taskTemplates?.length) {
      return {};
    }

    return taskTemplates.reduce<{ [id: number]: TaskTemplate }>(
      (acc, template) => ({ ...acc, [template.id]: template }),
      {}
    );
  }, [taskTemplates]);

  const selectedTemplate = useMemo(() => {
    if (!selectedWorkOrderTemplate || !taskTemplates?.length) {
      return null;
    }

    if (selectedWorkOrderTemplate.id === 'new') {
      return {
        ...initialValueTaskModal
      };
    }

    const { task } = selectedWorkOrderTemplate;

    return taskTemplatesById[task.id];
  }, [selectedWorkOrderTemplate, taskTemplates, taskTemplatesById]);

  const {
    projectStageAutomations,
    fetch: { data: automationsPage }
  } = useWorkflow();

  const handleCreateNewWorkOrderTemplate = useCallback(
    async ({
      stageId,
      sliceId,
      projectStage
    }: {
      stageId: number;
      sliceId: number | undefined;
      projectStage: WorkspaceStageFromAPI;
    }) => {
      setAwatingSliceForNewTemplate({ stageId, sliceId, projectStage });
      setSelectedWorkOrderTemplate({ ...initialValueTaskModal });
    },
    []
  );

  const [hoveredStage, setHoveredStage] = useState<number>(0);

  const tasksTable = useMemo(() => getTasksTable(blueprint), [blueprint]);

  const handleTaskTemplateCreated = useCallback(
    async (values: any) => {
      if (awatingSliceForNewTemplate) {
        const { stageId, sliceId, projectStage } = awatingSliceForNewTemplate;

        const confirmationAction = await confirmAddWO({
          woTemplate: { id: values.id, title: values.title, isField: values.isField },
          stage: projectStage,
          workflowStageId: stageId,
          sliceId: sliceId || undefined
        });

        if (!confirmationAction) {
          return;
        }

        await bindTaskToWorkflow({
          blueprintStageId: stageId,
          blueprintSliceId: sliceId,
          taskId: values.id,
          taskAction: confirmationAction.action,
          addToFeed: confirmationAction.addToFeed
        });
      }
    },
    [awatingSliceForNewTemplate, bindTaskToWorkflow, confirmAddWO]
  );

  const handleAutomationCreated = useCallback((automation: AutomationFromApi) => {
    setSelectedAutomation(automation);
  }, []);

  if (!blueprint || !workspaceProjectStages || !taskTemplates || !automationsPage) {
    return null;
  }

  const availableProjectStages = workspaceProjectStages.filter(
    (projectStage) =>
      !blueprint.projectStages.some((blueprintStage) => blueprintStage.projectStageId === projectStage.id) &&
      projectStage.scope === RecordType.PROJECT
  );

  const availableProjectDealStages = workspaceProjectStages.filter(
    (projectStage) =>
      !blueprint.projectStages.some((blueprintStage) => blueprintStage.projectStageId === projectStage.id) &&
      projectStage.scope === RecordType.DEAL
  );

  const availableTaskTemplates = (taskTemplates || []).filter(
    (template) =>
      !blueprint.projectStages.some((stage) => stage.blueprintTasks.some((task) => task.taskId === template.id))
  );

  const handleAddProjectStage = async (projectStage: WorkspaceStageFromAPI, afterStage?: BlueprintProjectStageDTO) => {
    await createProjectStage.mutateAsync({
      projectStageId: projectStage.id,
      afterStageId: afterStage?.id
    });
  };

  const handleRemoveProjectStage = async (stage: BlueprintProjectStageDTO) => {
    await deleteProjectStage.mutateAsync(stage.id);
  };

  const handleCreateTask = async (task: TaskTemplate, stage: BlueprintProjectStageDTO) => {
    if (!task) {
      setAwatingSliceForNewTemplate({ stageId: stage.id, projectStage: stage.projectStage, sliceId: undefined });
      setSelectedWorkOrderTemplate({ ...initialValueTaskModal });

      return;
    }

    const confirmationAction = await confirmAddWO({
      woTemplate: task,
      workflowStageId: stage.id,
      stage: stage.projectStage,
      sliceId: undefined
    });

    if (!confirmationAction) {
      return;
    }

    await bindTaskToWorkflow({
      taskId: task.id,
      blueprintStageId: stage.id,
      taskAction: confirmationAction.action,
      addToFeed: confirmationAction.addToFeed
    });
  };

  const getSliceTasks = (table: TasksTable, sliceId: number): BlueprintTaskDTO[] => {
    return flatten(table.map((stageSlices) => find(stageSlices, { sliceId })!.tasks.filter((task) => task.id > 0)));
  };

  return (
    <>
      <Breadcrumbs>
        <Link to="../">Workflows</Link>
        <ChevronRightIcon size="16px" color="#828D9A" />
        <div>{blueprint.name}</div>
      </Breadcrumbs>
      {blueprint.type === RecordType.PROJECT && (
        <>
          <InfoPanel
            title="Project flow"
            text={
              <p>
                The sequence of stages through which every Project in <strong>{blueprint.name}</strong> will travel, as
                well as the tasks in them.{' '}
              </p>
            }
            tooltip=""
          />

          <BlueprintFlow>
            {!blueprint.projectStages.length && (
              <Button
                first
                options={availableProjectStages}
                renderOption={(projectStage) => <ProjectStageBadge stage={projectStage} />}
                renderLabel={(projectStage) => projectStage.name}
                onAdd={(selectedStage) => handleAddProjectStage(selectedStage)}
              />
            )}
            {sortBy(blueprint.projectStages, 'position').map((stage, stageIndex, stages) => (
              <BlueprintStage key={stage.id}>
                <BlueprintStageTitleWrapper>
                  <Tooltip title={<TooltipWrapper>{stage.projectStage.name}</TooltipWrapper>} placement="top" arrow>
                    <BlueprintProjectStageTitle
                      type={stage.projectStage.type}
                      vertical
                      height={stage.blueprintTasks.length}
                    >
                      <Actions vertical>
                        <Button
                          options={availableProjectStages}
                          renderOption={(projectStage) => <ProjectStageBadge stage={projectStage} />}
                          renderLabel={(projectStage) => projectStage.name}
                          onAdd={(selectedStage) => handleAddProjectStage(selectedStage, stages[stageIndex - 1])}
                        />
                        <Button onRemove={() => handleRemoveProjectStage(stage)} />
                        <Button
                          options={availableProjectStages}
                          renderOption={(projectStage) => <ProjectStageBadge stage={projectStage} />}
                          renderLabel={(projectStage) => projectStage.name}
                          onAdd={(selectedStage) => handleAddProjectStage(selectedStage, stages[stageIndex])}
                        />

                        <AddAutomationButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedAutomation({
                              context: 'projectStage',
                              steps: [
                                {
                                  type: AutomationStepType.TRIGGER,
                                  key: Trigger.PROJECT_MOVEMENT,
                                  fields: [
                                    { key: 'fromStageId', value: stage.projectStageId },
                                    { key: 'toStageId', value: stage.projectStageId }
                                  ]
                                }
                              ]
                            });
                          }}
                        >
                          <Zap fill="#F7A902" color="#F7A902" size="16px" />
                        </AddAutomationButton>
                      </Actions>
                      {Boolean(projectStageAutomations[stage.projectStageId]?.length) && (
                        <ProjectStageAutomationsZap
                          placement="right-start"
                          automations={projectStageAutomations[stage.projectStageId]}
                          onClick={(automation) => setSelectedAutomation({ ...automation, context: 'projectStage' })}
                        />
                      )}
                      <span>{stage.projectStage.name}</span>
                    </BlueprintProjectStageTitle>
                  </Tooltip>
                </BlueprintStageTitleWrapper>
                <BlueprintStageSlicesWrapper onMouseOver={() => hoveredStage !== stage.id && setHoveredStage(stage.id)}>
                  <BlueprintStageSlices type={stage.projectStage.type}>
                    {!tasksTable[stageIndex].length && (
                      <Button<TaskTemplate>
                        first
                        options={availableTaskTemplates}
                        renderOption={(task) => task.title}
                        renderLabel={(task) => task.title}
                        isWorkOrderSelector
                        onAdd={(selectedTask) => handleCreateTask(selectedTask, stage)}
                      />
                    )}
                    {tasksTable[stageIndex].map(({ sliceId, tasks }) => (
                      <BlueprintSliceTasks key={`stage=${stage.id}_slice=${sliceId}`} type={stage.projectStage.type}>
                        {false && hoveredStage === stage.id && (
                          <AddSlice>
                            <ChevronLeft size={16} />
                            <ChevronRight size={16} />
                          </AddSlice>
                        )}

                        {stageIndex === 0 && sliceId && (
                          <TimesliceQuestionMark>
                            <Tooltip
                              title={
                                <TooltipWrapper>
                                  Once a project progresses to this stage, next tasks will appear on BACKLOG:
                                  {getSliceTasks(tasksTable, sliceId).map((task) => (
                                    <div key={task.id}>{task.task.title}</div>
                                  ))}
                                </TooltipWrapper>
                              }
                              placement="top"
                              arrow
                            >
                              <HelpCircle size={16} color={colors.gray} />
                            </Tooltip>
                          </TimesliceQuestionMark>
                        )}

                        {tasks
                          .filter((task) => task.id > 0 && taskTemplatesById[task.task.id])
                          .map((task) => (
                            <BlueprintTask
                              key={task.id}
                              task={task}
                              fullTask={taskTemplatesById[task.task.id]}
                              stageId={stage.id}
                              blueprintId={blueprintId}
                              onClick={setSelectedWorkOrderTemplate}
                              onAutomationClick={(automation) =>
                                setSelectedAutomation({ ...automation, context: 'workOrderTemplate' })
                              }
                              onFormClick={setSelectedForm}
                            />
                          ))}

                        {tasks
                          .filter((task) => task.id < 0)
                          .map(
                            (task, taskIndex) =>
                              (task.id < 0 && hoveredStage === stage.id && taskIndex === 0 && (
                                <BlueprintTaskPlaceholder
                                  key={task.id}
                                  blueprintId={blueprintId}
                                  stageIdToAddTo={stage.id}
                                  projectStage={stage.projectStage}
                                  sliceIdToAddTo={sliceId}
                                  availableTasks={availableTaskTemplates}
                                  onCreateNewWorkOrderTemplate={handleCreateNewWorkOrderTemplate}
                                />
                              )) ||
                              (task.id < 0 && <BlueprintFakeTask key={task.id} />)
                          )}
                      </BlueprintSliceTasks>
                    ))}
                  </BlueprintStageSlices>
                </BlueprintStageSlicesWrapper>
              </BlueprintStage>
            ))}
          </BlueprintFlow>

          {selectedTemplate && (
            <SlidePanelContainer>
              <OuterButtons>
                <CloseButton onClick={() => setSelectedWorkOrderTemplate(null)}>
                  <XIcon size="24px" />
                </CloseButton>
              </OuterButtons>

              <WorkOrderTemplateForm
                key={selectedTemplate.id}
                id={selectedTemplate.id}
                onCreated={handleTaskTemplateCreated}
                onClose={() => setSelectedWorkOrderTemplate(null)}
              />
            </SlidePanelContainer>
          )}

          {selectedAutomation && (
            <SlidePanelContainer>
              <OuterButtons>
                <CloseButton onClick={() => setSelectedAutomation(null)}>
                  <XIcon size="24px" />
                </CloseButton>
              </OuterButtons>
              <AutomationFormContainer>
                <AutomationForm initialValues={selectedAutomation} onCreated={handleAutomationCreated} />
              </AutomationFormContainer>
            </SlidePanelContainer>
          )}

          {selectedForm && (
            <FormSlidePanel isMainMenuExpanded={isDrawerOpen}>
              <OuterButtons>
                <CloseButton onClick={() => setSelectedForm(null)}>
                  <XIcon size="24px" />
                </CloseButton>
              </OuterButtons>
              <FormBuilderContainer formId={selectedForm.id} />
            </FormSlidePanel>
          )}
        </>
      )}
      {blueprint.type === RecordType.DEAL && (
        <>
          <InfoPanel
            title="Request flow"
            text={
              <p>
                The sequence of stages through which every Request in <strong>{blueprint.name}</strong> will pass
              </p>
            }
            tooltip="Tooltip text"
          />

          <BlueprintFlow vertical>
            {!blueprint.projectStages.length && (
              <Button
                first
                options={availableProjectDealStages}
                renderOption={(projectStage) => <ProjectStageBadge stage={projectStage} />}
                renderLabel={(projectStage) => projectStage.name}
                onAdd={(selectedStage) => handleAddProjectStage(selectedStage)}
              />
            )}
            {sortBy(blueprint.projectStages, 'position').map((projectStage, stageIndex, stages) => (
              <BlueprintStage key={projectStage.projectStage.id}>
                <BlueprintProjectStageTitle type={projectStage.projectStage.type} vertical={false}>
                  <Actions>
                    <Button
                      options={availableProjectDealStages}
                      renderOption={(projectStage) => <ProjectStageBadge stage={projectStage} />}
                      renderLabel={(projectStage) => projectStage.name}
                      onAdd={(selectedStage) => handleAddProjectStage(selectedStage, stages[stageIndex - 1])}
                    />
                    <Button onRemove={() => handleRemoveProjectStage(projectStage)} />
                    <Button
                      options={availableProjectDealStages}
                      renderOption={(projectStage) => <ProjectStageBadge stage={projectStage} />}
                      renderLabel={(projectStage) => projectStage.name}
                      onAdd={(selectedStage) => handleAddProjectStage(selectedStage, stages[stageIndex])}
                    />
                  </Actions>
                  <span>{projectStage.projectStage.name}</span>
                </BlueprintProjectStageTitle>
              </BlueprintStage>
            ))}
          </BlueprintFlow>
        </>
      )}
    </>
  );
};

export default Blueprint;
