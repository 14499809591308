import { PortalNotificationEvent } from '@hooks/workspace/notifications/usePortalNotifications';
import React from 'react';
import { ScheduledEventForm } from './ScheduledEventForm';
import { DefaultEventForm } from './DefaultEventForm';

interface Props {
  event: PortalNotificationEvent;
  onClose: () => void;
}

export const EventForm = ({ event, onClose }: Props) => {
  return event.eventSchema.scheduled ? (
    <ScheduledEventForm event={event} onClose={onClose} />
  ) : (
    <DefaultEventForm event={event} onClose={onClose} />
  );
};
