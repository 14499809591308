import { ReactQueryKey } from '@enums';
import blueprintAPI from '@services/api/blueprintAPI';
import { apiErrorHandler } from '@utils/api';
import { useQuery } from 'react-query';

type Params = {
  workflowId: number;
  workflowStageId: number;
  sliceId?: number;
  workOrderId: number;
};

export const usePreCreateWorkflowWorkOrderInfo = (params: Params) => {
  return useQuery([ReactQueryKey.WorkflowPreCreateWorkOrderInfo, params], async () => {
    try {
      const { data } = await blueprintAPI.preCreateTask(params.workflowId, {
        blueprintStageId: params.workflowStageId,
        blueprintSliceId: params.sliceId,
        taskId: params.workOrderId
      });

      return data;
    } catch (e) {
      throw apiErrorHandler('Error fetching info for creating Work Order', e);
    }
  });
};
