import React, { useMemo } from 'react';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { CheckboxGroupField, Form, FormValidationRules, useForm } from '@kit/components/Form';
import { useToast } from '@hooks/useToast';
import { useUpdateCompanyNotification } from '@hooks/workspace/notifications/useUpdateCompanyNofification';
import { PortalNotificationEvent } from '@hooks/workspace/notifications/usePortalNotifications';
import {
  clearQuillValue,
  clearQuillValueForSms,
  convertQuillFormatToText,
  convertTextToQuillFormat,
  isQuilValueEmpty
} from '@utils/quill';
import { ModalBody, ModalFooter } from '@common/PromiseModal';
import { TemplateContentEditor } from './TemplateContentEditor';

interface FormValues {
  deliveryMethod: string[];
  emailNotificationSubject: string;
  emailNotificationText: string;
  smsNotificationText: string;
}

const DELIVERY_METHODS = [
  { label: 'Email', value: 'email', settingsKey: 'emailEnabled' },
  { label: 'SMS', value: 'sms', settingsKey: 'smsEnabled' }
];

const getDefaultValues = (event: PortalNotificationEvent) => {
  return {
    deliveryMethod: DELIVERY_METHODS.filter((method) => event.settings[method.settingsKey]).map(
      (method) => method.value
    ),
    emailNotificationSubject: convertTextToQuillFormat(event.settings.emailNotificationSubject),
    emailNotificationText: convertTextToQuillFormat(event.settings.emailNotificationText),
    smsNotificationText: convertTextToQuillFormat(event.settings.smsNotificationText)
  };
};
interface Props {
  event: PortalNotificationEvent;
  onClose: () => void;
}

export const DefaultEventForm = ({ event, onClose }: Props) => {
  const { mutateAsync: update } = useUpdateCompanyNotification();

  const { showError } = useToast();

  const postForm = async (values: FormValues) => {
    if (!values.deliveryMethod.length) {
      showError('At least one delivery method should be selected');

      return;
    }

    await update({
      entityType: event.settings.entityType,
      eventType: event.settings.eventType,
      emailEnabled: values.deliveryMethod.includes('email'),
      smsEnabled: values.deliveryMethod.includes('sms'),
      emailNotificationSubject: clearQuillValue(convertQuillFormatToText(values.emailNotificationSubject)),
      emailNotificationText: convertQuillFormatToText(values.emailNotificationText),
      smsNotificationText: clearQuillValueForSms(convertQuillFormatToText(values.smsNotificationText))
    });

    onClose();
  };

  const { form, handleSubmit } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: getDefaultValues(event)
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { control, reset, watch } = form;

  const rules = useMemo<FormValidationRules<FormValues>>(() => {
    return {
      deliveryMethod: {
        isRequired: true
      },
      emailNotificationSubject: {
        isRequired: true,
        validate: (value) => {
          if (value && isQuilValueEmpty(value as string)) {
            return 'This is required';
          }

          return undefined;
        }
      },
      emailNotificationText: {
        isRequired: true,
        validate: (value) => {
          if (value && isQuilValueEmpty(value as string)) {
            return 'This is required';
          }

          return undefined;
        }
      },
      smsNotificationText: {
        isRequired: true,
        validate: (value) => {
          if (value && isQuilValueEmpty(value as string)) {
            return 'This is required';
          }

          return undefined;
        }
      }
    };
  }, []);

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <ModalBody width="815px">
        <CheckboxGroupField
          layout="row"
          name="deliveryMethod"
          label="Delivery methods"
          options={DELIVERY_METHODS}
          control={control}
        />

        <TemplateContentEditor
          control={control}
          emailSubjectFieldName="emailNotificationSubject"
          emailTextFieldName="emailNotificationText"
          smsTextFieldName="smsNotificationText"
          eventType={event.settings.eventType}
          watch={watch}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose} variant={ButtonVariant.Secondary}>
          Cancel
        </Button>
        <Button type="submit" variant={ButtonVariant.Primary}>
          Update
        </Button>
      </ModalFooter>
    </Form>
  );
};
