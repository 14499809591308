import { TaskTemplate, WorkspaceStageFromAPI } from '@types';
import React from 'react';
import { useBlueprintMutations } from '@hooks';
import { Wrapper } from './styled';
import { Button } from '../common/Button';
import { useConfirmAddWO } from '../useConfirmAddWO';

interface BlueprintTaskPlaceholderProps {
  blueprintId: number;
  stageIdToAddTo: number;
  projectStage: WorkspaceStageFromAPI;
  sliceIdToAddTo: number | null;
  availableTasks: TaskTemplate[];
  onCreateNewWorkOrderTemplate: (params: {
    stageId: number;
    projectStage: WorkspaceStageFromAPI;
    sliceId: number | undefined;
  }) => void;
}

export const BlueprintTaskPlaceholder: React.FC<BlueprintTaskPlaceholderProps> = (props) => {
  const { blueprintId, stageIdToAddTo, projectStage, sliceIdToAddTo, availableTasks, onCreateNewWorkOrderTemplate } =
    props;

  const { createTask } = useBlueprintMutations(blueprintId);

  const confirmAddWO = useConfirmAddWO(blueprintId);

  const handleCreateTask = async (task: TaskTemplate) => {
    if (!task) {
      onCreateNewWorkOrderTemplate({ stageId: stageIdToAddTo, sliceId: sliceIdToAddTo || undefined, projectStage });

      return;
    }

    const confirmationAction = await confirmAddWO({
      woTemplate: task,
      workflowStageId: stageIdToAddTo,
      stage: projectStage,
      sliceId: sliceIdToAddTo || undefined
    });

    if (!confirmationAction) {
      return;
    }

    await createTask.mutateAsync({
      blueprintStageId: stageIdToAddTo,
      blueprintSliceId: sliceIdToAddTo || undefined,
      taskId: task.id,
      taskAction: confirmationAction.action,
      addToFeed: confirmationAction.addToFeed
    });
  };

  return (
    <Wrapper>
      <Button
        placeholder
        onAdd={handleCreateTask}
        options={availableTasks}
        renderOption={(taskTemplate) => taskTemplate.title}
        renderLabel={(taskTemplate) => taskTemplate.title}
        isWorkOrderSelector
      />
    </Wrapper>
  );
};
