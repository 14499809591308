import React from 'react';
import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import { WorkOrderStatus } from '@components/WorkOrder/WorkOrderStatus';
import { Calendar, X } from 'react-feather';
import { PriorityIcon } from '@common/PriorityIcon';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { Button, ButtonSize, ButtonVariant, IconButton } from '@kit/ui/Button';
import { DateTime, Interval } from 'luxon';
import { Avatar } from '@kit/ui/Avatar';
import { getFullName } from '@utils';
import { PhoneNumber } from '@kit/ui/anchors/PhoneNumber';
import { Address } from '@kit/ui/anchors/Address';
import { ProjectTitleChip } from '@components/Scheduler/components/ProjectTitleChip';
import {
  Assignee,
  Container,
  FieldLabel,
  FifthRow,
  FirstRow,
  FourthRow,
  Separator,
  SeventhRow,
  SixthRow,
  TaskDate,
  TitleShrink,
  Title,
  TitleNoShrink
} from './styled';
import { TaskWithDates } from '../types';

type Props = {
  task: TaskWithDates;
  handleClose: () => void;
};

export const TaskPopup = ({ task, handleClose }: Props) => {
  const { openDrawer } = useDrawersContext();
  const openTaskDrawer = () => openDrawer(DrawerEntity.WORK_ORDER, task.id, []);

  const firstVisit = task.taskVisitsByTaskIdConnection.nodes[0];

  return (
    <Container>
      <FirstRow>
        <Title>
          <TitleNoShrink>
            <WorkOrderTypeIcon isField={task.isField} size="16px" />
          </TitleNoShrink>
          <TitleShrink title={task.title}>
            {task.uid ? `#${task.uid}` : ''} · {task.title}
          </TitleShrink>
          <TitleNoShrink>
            <PriorityIcon priority={task.priority} />
          </TitleNoShrink>
        </Title>

        <IconButton variant={ButtonVariant.Flat} size={ButtonSize.Small} onClick={handleClose}>
          <X size={16} color="#9C9CAA" />
        </IconButton>
      </FirstRow>

      <div>
        <WorkOrderStatus status={task.taskStatus} />
      </div>

      <div>
        <ProjectTitleChip iconSize="16px" project={task.project} />
      </div>

      <FourthRow>
        <Address iconSize="16px" value={task.project.address[0]} />
      </FourthRow>

      <FifthRow>
        <PhoneNumber iconSize="16px" value={task.project.projectContacts[0]?.phones[0]} />
      </FifthRow>

      <Separator />

      <SixthRow>
        <FieldLabel>First visit:</FieldLabel>
        {firstVisit ? (
          <TaskDate>
            <Calendar size={16} color="#9C9CAA" />
            {Interval.fromDateTimes(
              DateTime.fromISO(firstVisit.startDate),
              DateTime.fromISO(firstVisit.endDate)
            ).toLocaleString(DateTime.DATETIME_SHORT)}
          </TaskDate>
        ) : (
          <FieldLabel>Not scheduled</FieldLabel>
        )}
      </SixthRow>

      <SeventhRow>
        <FieldLabel>Assignee:</FieldLabel>
        {task.assignee ? (
          <Assignee>
            <Avatar user={task.assignee} />
            {getFullName(task.assignee)}
          </Assignee>
        ) : (
          <FieldLabel>Not assigned</FieldLabel>
        )}
      </SeventhRow>

      <Button variant={ButtonVariant.Secondary} onClick={openTaskDrawer}>
        View details
      </Button>
    </Container>
  );
};
